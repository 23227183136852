.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2.1rem;
    background: var(--very-dark-blue);
    height: 21rem;
}
.footer-bm{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 17rem;
}
.footer-attribution{
    display: flex;
    flex-direction: row;
    text-align: center;
    height: 4rem;
    align-items: center;
}
.footer-attribution p{
   font-size:0.8rem ; 
   margin: 0;
}
.footer-attribution p a{
    display: inline;
    font-size:0.8rem ;
    color: var(--soft-blue);
    transition: color .3s ease;
 }
.footer-attribution p a:hover{
    color: var(--soft-red);
}
.footer-nav{
    display: flex;
    height: 11rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.footer-icons{
    display: flex;
    align-items: center;
    width: 5.5rem;
    justify-content: space-between;    
    margin: 0 auto;
}
.logo-footer{
    margin-right: 0;
}
.icon-footer{
    cursor: pointer;
    transition: ease .3s;
}

.icon-footer:hover{
    --icon:var(--soft-red) ;
}
@media only screen and (min-width:65rem) {
    .footer{
        flex-direction: column;
        padding: 2rem 10.2rem;
        height: 5.5rem;
    }
    .footer-nav{
        flex-direction: row;
    }
    .footer-nav{
        height: 1rem;
    }
    .footer-icons{
          
        margin: 0 0;
    }
    .logo-footer{
        margin-right: 2.5rem;
    }
    .footer-bm{
        flex-direction: row;
        height: auto;
        width: 100%;
        align-items: center;
    }
    .footer-attribution{
        flex-direction: row;
        height: auto;
    }
    .footer-attribution p{
       font-size:0.8rem ; 
       margin: 0.5rem 0 0 0;
    }
    .footer-attribution p a{
        display: inline;
        font-size:0.8rem ;
     }
}