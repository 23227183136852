.btn{
    cursor: pointer;
    padding: 0.7rem 1.8rem;
    border: none;
    font-family: 'Rubik', sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 0.3rem;
    margin-left: 1rem;
    transition: background ease .3s;
    box-shadow: 1px 8px 14px -7px rgba(163, 159, 219, 0.45);
    color: var(--white);
    letter-spacing: 0.04rem;
}
.btn-blue{
    padding: 0.85rem 0.83rem;
    border: 0.15rem solid var(--soft-blue);
    background: var(--soft-blue);
    margin-left:0 ;
}
.btn-red{
    letter-spacing: 0.1rem;
    border: 0.15rem solid var(--soft-red);
    background: var(--soft-red);
}
.btn-white{
    padding: 0.85rem 0.83rem;
    border:  0.15rem solid var(--grayish-blue-light);
    background: var(--grayish-blue-light);
    color:var(--very-dark-blue);
}
.btn-blue:hover{
    background: var(--white);
    color: var(--soft-blue);
    border: 0.15rem solid  var(--soft-blue) ;
}
.btn-red:hover{
    background: var(--white);
    color: var(--soft-red);
    border:   0.15rem solid var(--soft-red) ;
}
.btn-white:hover{
    color: var(--very-dark-blue);
    background: var(--white);
    border:  0.15rem solid var(--very-dark-blue) ;
}
@media only screen and (min-width:40rem) {
    .btn-white{
        padding: 0.85rem 1.5rem;
    }
    .btn{
        margin-left: 1.5rem;
    }
    .btn-blue{
        margin-left:0 ;
        padding: 0.85rem 1.5rem;
    }
}