.section-contact{
    background-color: var(--soft-blue);
    padding: 3.6rem 0;
    margin-bottom: 0;
}
.contact-subtitle{
    color: var(--white);
}
.contact-des{
    font-size: 0.7rem;
    font-weight: 400;
    letter-spacing: 0.4rem;
    color: var(--white);
}
.contact-input{
    z-index: 1;
    padding: 0.75rem 1.1rem;
    border: 0.2rem solid var(--white);
    border-radius: 0.3rem;
}
.contact-input::placeholder{
    font-family: 'Rubik', sans-serif;
    color: var(--grayish-blue); 
    opacity: 0.6;
}
form{
    display: flex;
    flex-direction: column;
    width: 85%;
    max-width: 30rem;
    margin: 2rem auto 0 auto;
    justify-content: space-between;
}
.btn-contact{
    padding: 0.8rem 1.2rem;
    margin-left: 0;
    margin-top: 2rem;
    letter-spacing: 0.02rem;
}
.btn-open{
    width: 90%;
    background: transparent;
    border-color: var(--white);
    margin: 0;
    font-size: 1.2rem;
}
.email-validate{
    width: 100%;
    max-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}
.email-error{
    position: absolute;
    color: var(--white);
    font-weight: 500;
    font-style: italic;
    font-size: 0.65rem;
    top: -0.2rem;
    left: -0.2rem ;
    width: 101.5%;
    max-width: 101.5%;
    text-align: left;
    padding: 3.4rem 0 0.4rem 0.7rem;
    border-radius: 0.3rem;
    background-color: transparent;
}
.bg-red{
    background-color: var(--soft-red);

}
@media only screen and (min-width:65rem) {
    .section-contact{
        padding: 5rem 0;
    }
    form{
        flex-direction: row;
        width: 100%;
        max-width: 28rem;
    }
    .email-validate{
        width: 100%;
        max-width: 19.6rem;
    }
    .contact-input{
        padding: 0.75rem 1.1rem;
        border: 0.2rem solid var(--white);
        border-radius: 0.3rem;
    }
    .btn-contact{
        margin-left: 1rem;
        margin-top: 0;
    }
    .email-error{
        width: 102%;
        max-width: 20rem;
    }
}
