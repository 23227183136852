.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.2rem ;
}
.navbar{
    display: none;
    position: absolute;
    background-color: var(--blue-transparent);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    z-index: 1;
}
.navbar.open{
    display: flex;
}
.hamburguer{
    cursor: pointer;
    background-color: transparent;
    border: none;
    content: url(../img/icon-hamburger.svg);
    display: flex;
}
.hamburguer.open{
    z-index: 2;
    content: url(../img/icon-close.svg);
    display: flex;
}
@media only screen and (min-width:65rem) {
    .header{
        background-color: var(--white);
        padding: 3.1rem 10.6rem;
    }
    .navbar{
        display: flex;
        position: initial;
        flex-direction: row;
        background-color: var(--white);
        min-height: auto;

    }
    .hamburguer{
        display: none;
    }
}