@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');
:root{
    --soft-blue:hsl(231, 69%, 60%);
    --soft-red: hsl(0, 94%, 66%);
    --grayish-blue: hsl(229, 8%, 60%);
    --grayish-blue-light: hsl(0, 3%, 94%);
    --very-dark-blue: hsl(229, 31%, 21%);
    --blue-transparent: hsla(229, 31%, 21%,0.9);
    --white:rgb(255, 255, 255);
    --icon:rgb(255, 255, 255);
}
html{
    box-sizing: border-box;
}
*,*::after,*::before{
    box-sizing: inherit;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}
body{
    font-family: 'Rubik', sans-serif;
    background: var(--white);
    font-size: 18px;
    overflow: auto;
}
a{
    text-decoration: none;
    display: flex;
    align-items: center;
}
.App{
    overflow: hidden;
}
h1,h2{
    color: var(--very-dark-blue);
    font-weight: 500;
    margin-bottom: 1.5rem;
}
h2{
    font-size: 1.5rem;
    width: 85%;
    max-width: 30rem;
    margin: 0 auto 1.4rem auto;
}
h3{
    color: var(--very-dark-blue);
    font-weight: 500;
    margin-bottom: 0.6rem;
}
p{
    color: var(--grayish-blue);
    margin-bottom: 2rem;
    line-height: 1.5;
    font-size: 0.93rem;
}
@media only screen and (min-width:40rem) {
    p{
        font-size: 1.125rem;
    }
    h2{
        font-size: 2rem;
    }
}