section{
    margin-bottom: 3.8rem;
    padding: 2.8rem 0;
}
.section-secondary{
    text-align: center;
}
.section-description{
    width: 80%;
    max-width: 34rem;
    margin: 0 auto;
}